import { useState } from "react";
import { Button, Form, notification } from "antd";
import { getRandomKey } from "../../Utilities";
import GuestInput from "../form/GuestInput";
import "./style.css";

const defaultGuest = {
  key: "",
  firstName: "",
  lastName: "",
  isAttending: null,
  isChild: "No",
  mealOption: null,
  email: "",
  dietary: "",
};

const Rsvp = () => {
  const [guests, setGuests] = useState([]);

  const addGuest = () => {
    if (guests.length < 10) {
      setGuests([...guests, { ...defaultGuest, key: getRandomKey() }]);
    }
  };

  const removeGuest = (key) => {
    const newGuests = guests.filter((guest) => guest.key !== key);
    setGuests([...newGuests]);
  };

  const setGuestData = (key, newData) => {
    const newGuests = guests.map((guest) => {
      if (guest.key === key) {
        return newData;
      } else {
        return guest;
      }
    });
    setGuests([...newGuests]);
  };

  const submit = () => {
    if (process.env.REACT_APP_PIPEDREAM_URL) {
      fetch(process.env.REACT_APP_PIPEDREAM_URL, {
        method: "POST",
        body: JSON.stringify({ data: guests }),
        headers: { "Content-Type": "application/json" },
      })
        .then(() => {
          notification["success"]({
            message: "RSVPs Sent!",
            description: "Thank you for sending us your RSVPs.",
          });
          setGuests([{ ...defaultGuest, key: getRandomKey() }]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div className="rsvp-wrap">
              {/* <div className="col-12">
                <div className="section-title section-title4 text-center">
                  <h2 style={{ marginTop: 0 }}>Be Our Guest</h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 500 }}>
                      RSVP by June 15th, 2024.
                      <br />
                      Please complete an RSVP Card for each invitee.
                    </p>
                    <div
                      style={{
                        fontSize: "12px",
                        width: "80%",
                      }}
                    >
                      Our reception will include a barbecue buffet followed by
                      tea, coffee, and dessert; hosted by Skipper Ben's.
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <h4 style={{ textAlign: "center" }}>
                This page is temporarily unavailable. Please check back later.
              </h4> */}

              {/* <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  alignSelf: "center",
                  margin: "30px 0px 0px",
                  gap: "16px",
                }}
              >
                {guests.map((guest, index) => (
                  <GuestInput
                    key={guest.key}
                    guestData={guest}
                    setGuestData={setGuestData}
                    isSingleInput={guests.length === 1}
                    onDelete={() => {
                      removeGuest(guest.key);
                    }}
                  />
                ))}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    gap: "8px",
                  }}
                >
                  <Button
                    ghost
                    type="primary"
                    disabled={guests.length >= 10}
                    onClick={addGuest}
                  >
                    Add an RSVP Card
                  </Button>
                  <Button
                    disabled={guests.length <= 0}
                    type="primary"
                    onClick={() => {
                      submit();
                    }}
                  >
                    Submit Responses
                  </Button>
                </div>
              </Form> */}

              <div style={{ position: "relative" }}>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSdoO_j7gU5dwxzvsm8Rj1v0-ktyd4k5ymbBxceyK62yj8u9Mw/viewform?embedded=true"
                  width="100%"
                  height="960px"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                  title="RSVP Form"
                >
                  Loading…
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rsvp;
