import React from "react";
import { ConfigProvider } from "antd";
import theme from "../../theme";
import Scrollbar from "../../components/scrollbar";
import "./App.css";
import AllRoute from "../router";

const App = () => {
  return (
    <ConfigProvider theme={theme}>
      <AllRoute />
      {/* <Scrollbar /> */}
    </ConfigProvider>
  );
};

export default App;
