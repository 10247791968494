import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../Pages/HomePage";
import RsvpPage from "../Pages/RsvpPage";
import StoryPage from "../Pages/StoryPage";
import LocationPage from "../Pages/LocationPage";
import HoneymoonPage from "../Pages/HoneymoonPage";
import PlaylistPage from "../Pages/PlaylistPage";
import LivePage from "../Pages/LivePage";

const AllRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/rsvp" element={<RsvpPage />} />
        <Route path="/story" element={<StoryPage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/honeymoon" element={<HoneymoonPage />} />
        <Route path="/playlist" element={<PlaylistPage />} />
        <Route path="/live" element={<LivePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoute;
