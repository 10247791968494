import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const SimpleSlider = () => {
  const navigate = useNavigate();

  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <Slider {...settings}>
      <div id="home" className="item1">
        <div className="slide-content">
          {/* <div className="slide-subtitle">
              <h4>WERE GETTING MARRIED</h4>
            </div> */}
          <div className="slide-title">
            <h2>Save the Date</h2>
          </div>
          <div className="slide-text">
            <p>August 10, 2024</p>
          </div>
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={() => {
              navigate("/rsvp");
            }}
          >
            RSVP
          </Button>
        </div>
      </div>
      {/* <div className="item2">
        <div className="slide-content">
          <div className="slide-title">
            <h2>Save the Date</h2>
          </div>
          <div className="slide-text">
            <p>August 10, 2024</p>
          </div>
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={() => {
              navigate("/rsvp");
            }}
          >
            RSVP
          </Button>
        </div>
      </div> */}
    </Slider>
  );
};

export default SimpleSlider;
