import React from "react";
import Couple from "../../components/couple";
import SimpleSlider from "../../components/hero";
import Story from "../../components/story";
import Welcome from "../../components/welcome-area";
import People from "../../components/people";
import Location from "../../components/location";
import Gallery from "../../components/gallery";
import Rsvp from "../../components/rsvp";
import Gift from "../../components/gift";
import Header from "../../components/Header";
import Saveday from "../../components/countdown";

const Homepage = () => {
  return (
    <div>
      <Header />
      <SimpleSlider />
      <Saveday />
      <div
        style={{
          margin: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontSize: "100px", textAlign: "center" }}>
          More to Come!
        </h2>
      </div>
      {/* <Couple /> */}
      {/* <Welcome /> */}
      {/* <Gallery /> */}
    </div>
  );
};

export default Homepage;
