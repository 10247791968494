import React from "react";
import Header from "../../components/Header";
import Location from "../../components/location";

const LocationPage = () => {
  return (
    <div>
      <Header bgFilled={true} />
      <Location />
    </div>
  );
};

export default LocationPage;
