import React from "react";
import Header from "../../components/Header";

const HoneymoonPage = () => {
  return (
    <div>
      <Header bgFilled={true} />
      HoneymoonPage
    </div>
  );
};

export default HoneymoonPage;
