import React from "react";
import Rsvp from "../../components/rsvp";
import Header from "../../components/Header";

const RsvpPage = () => {
  return (
    <>
      <Header bgFilled={true} />
      <Rsvp />
    </>
  );
};

export default RsvpPage;
