import colors from "./colors";

const theme = {
  token: {
    colorPrimary: colors.OLD_ROSE,
    colorPrimaryHover: "pink",
    colorLink: colors.OLD_ROSE,
    colorLinkActive: colors.OLD_ROSE,
    colorLinkHover: "pink",
  },
  components: {
    Button: {
      primaryShadow: "none",
      defaultHoverColor: "pink",
    },
    Form: {},
    Input: {
      activeBorderColor: colors.OLD_ROSE,
      hoverBorderColor: colors.OLD_ROSE,
      activeShadow: "none",
    },
  },
};

export default theme;
