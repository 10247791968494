import React from "react";
import Story from "../../components/story";
import Header from "../../components/Header";

const StoryPage = () => {
  return (
    <>
      <Header bgFilled={true} />
      <Story />
    </>
  );
};

export default StoryPage;
