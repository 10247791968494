import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: false,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;

    return (
      <>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 30px",
            }}
          >
            <div className="close" onClick={this.menuHandler}>
              Close
            </div>
          </div>
          <ul className="responsivemenu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/rsvp">RSVP</Link>
            </li>
            {/* <li>
              <Link to="/story">Our Story</Link>
            </li>
            <li>
              <Link to="/location">Location</Link>
            </li>
            <li>
              <Link to="/honeymoon">Honeymoon</Link>
            </li>
            <li>
              <Link to="/playlist">Playlist</Link>
            </li>
            <li>
              <Link to="/live">Live</Link>
            </li> */}
          </ul>
        </div>

        <div className="show-menu-button" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </>
    );
  }
}
