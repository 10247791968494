import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";

import "./style.css";

const Header = ({ bgFilled }) => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.documentElement.scrollTop = 0;
    };
  }, []);

  const className = scroll > 80 && "scrolled animated fadeInDown";

  return (
    <>
      <div className={`header ${className} ${bgFilled && "scrolled"}`}>
        <div className="logo">
          <h2>
            <Link to="/home">
              Melissa
              <span>
                <i className="fa fa-heart" aria-hidden="true"></i>
              </span>
              Chad
            </Link>
          </h2>
        </div>
        <div className="header-menu d-lg-block d-none">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/rsvp">RSVP</Link>
            </li>
            {/* <li>
              <Link to="/story">Our Story</Link>
            </li>
            <li>
              <Link to="/location">Location</Link>
            </li>
            <li>
              <Link to="/honeymoon">Honeymoon</Link>
            </li>
            <li>
              <Link to="/playlist">Playlist</Link>
            </li>
            <li>
              <Link to="/live">Live</Link>
            </li> */}
          </ul>
        </div>
        <MobileMenu />
      </div>
    </>
  );
};

export default Header;
