import React from "react";
import { Link } from "react-router-dom";
import strory1 from "../../images/story/1.jpg";
import strory2 from "../../images/story/2.jpg";
import strory3 from "../../images/story/3.jpg";
import strory4 from "../../images/story/4.jpg";
import "./style.css";

const Story = () => {
  return (
    <div id="story" className="story-area section-padding">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Our Story</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="story clearfix">
              <div className="story-top"></div>
              <div className="content-wrapper">
                <div className="item">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={strory1} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>First time we met</h2>
                    <span className="date">July 20, 2017</span>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some,
                    </p>
                    <div className="story-button">
                      <Link className="theme-btn" to="/" title="READ MORE">
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={strory2} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Our First Date</h2>
                    <span className="date">Dec 25, 2017</span>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some,
                    </p>
                    <div className="story-button">
                      <Link className="theme-btn" to="/" title="READ MORE">
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={strory3} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Our Marriage Proposal</h2>
                    <span className="date">Jan 10, 2018</span>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some,
                    </p>
                    <div className="story-button">
                      <Link className="theme-btn" to="/" title="READ MORE">
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={strory4} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Our Engagement</h2>
                    <span className="date">Jan 22, 2018</span>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some,
                    </p>
                    <div className="story-button">
                      <Link className="theme-btn" to="/" title="READ MORE">
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="story-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
